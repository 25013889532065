@import "style.commons.scss";
/* ##############################################

Código loader Spinner Graduate fuera de la aplicación

############################################## */

$loaderSize: 250px;

.outside-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    background-image: url("/assets/img/landing/logo-nav.png");
    background-size: 100% auto;
    width: $loaderSize;
    height: $loaderSize;
    background-position: center;
    background-repeat: no-repeat;
    @include animation("outside-loader-anim 1.1s infinite ease");
}

@include keyframes(outside-loader-anim) {
    0%,
    100% {
        opacity: 0;
    }
    40%,
    60% {
        opacity: 1;
    }
}
